.ContLocation {
   display: flex;
   flex-wrap: wrap;
   justify-content: space-evenly;

   background-color: #F6F6F6;
   border-radius: 30px;
   padding: 35px 0;
   margin: 50px 0;

   @media (max-width: 1001px) {
      align-items: center;
      background-color: white;
      padding: 35px 0;
      margin: 0 0;
   }
}

.ContCard {
   cursor: pointer;
   width: 340px;
   height: 340px;
   margin: 30px;
   transition: 0.2s;
   
   @media (max-width: 1001px) {
      width: 85vw;
      height: 250px;
   }

   &:hover {
      scale: 1.05;
}
}



.ImgLocation {
   object-fit: cover;
   width: 340px;
   height: 340px;
   border-radius: 15px;

   @media (max-width: 1001px) {
      width: 85vw;
      height: 250px;
   }
}

.GradientLocation {
   background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
   width: 100%;
   height: 100%;
   border-radius: 15px;

   position: relative;
   bottom: 345px;
   @media (max-width: 1001px) {
      bottom: 255px;
   }
}

.TitleLocation {
   color: white;
   font-size: 18px;
   position: relative;
   width: 240px;

   bottom: 395px;
   left: 20px;
   margin: 0;
   @media (max-width: 1001px) {
      bottom: 305px;
   }
}