.BannerCont {
   width: 100%;
   height: 223px;
   margin: 70px 0;
   display: flex;
   justify-content: center;
   align-items: center;

   @media (max-width: 1001px) {
      height: 150px;
      margin: 0 0;
   }

   &::after {
      content: "Chez vous, partout et ailleurs";
      z-index: 1;
      position: absolute;
      color: white;
      font-family: 'Montserrat';
      font-size: 27px;
      font-weight: 700;
      text-align: center;
   
      @media (max-width: 429px) {
         font-size: 20px;
     }              
   }
}

.BannerImg {
   width: 100%;
   height: 223px;
   object-fit: cover;
   border-radius: 30px;
   @media (max-width: 1001px) {
      height: 150px;
   }
}