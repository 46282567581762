.ContFooter {
   background-color: black;
   height: 210px;

   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}

.FooterText {
   color: white;
   font-size: 18px;
   @media (max-width: 1001px) {
      font-size: 15px;
   }
}