.ContSlider {
   display: flex;
   align-items: center;
   justify-content: center;

   position: relative;
}
.ImgSlider {
   width: 100%;
   height: 415px;
   object-fit: cover;
   border-radius: 15px;
   @media (max-width: 1001px) {
      height: 255px;
   }
}
.ArrowSliderLeft {
   position: absolute;
   cursor: pointer;
   right: 2%;
   @media (max-width: 1001px) {
      width: 7%;
   }
}
.ArrowSliderRight {
   position: absolute;
   cursor: pointer;
   left: 2%;
   @media (max-width: 1001px) {
      width: 7%;
   }
}
.CountSlider {
   position: absolute;

   bottom: 0%;

   color: white;
   font-weight: 700;
}