.ContCollapse {
   font-size: 24px;
   width: 80%;
   margin: 40px auto;
   border-radius: 5px;
   width: 100%;

}
.TitleCont {
   background-color: #FF6060;
   padding: 10px 20px;
   border-radius: 5px;
   display: flex;
   justify-content: space-between;
   align-items: center;
   cursor: pointer;
}
.TitleCollapse {
   font-weight: 500;
   margin: 0;
   color: white;
   font-size: 18px;
}
.TextCollapse {
   background-color: #F6F6F6;
   font-weight: 300;
   padding: 0;
   margin: 0;
   color: black;
   font-size: 17px;
   overflow: hidden;
   transition: height 0.8s ease, opacity 1.0s ease;
   height: 0;
   opacity: 0;

   &Open {
      padding: 30px 20px;
      height: auto;
      opacity: 1;
    }

   & p {
      padding: 0 0 5px 0;
   }
 }
 
 


.CollapseHomeTwo{
   display: flex;
   gap: 35px;
   @media (max-width: 1001px) {
      display: block;
   }
}

